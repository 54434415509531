import React, { useEffect, useState } from "react";
import { Wrapper, PageContainer } from "../Customers/Home";
import styled from "styled-components";
import { BASE_URL } from "config/config";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";
import {ReactComponent as Edit} from '../../../assets/images/edit.svg';

const Home = () => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const [loading, setLoading] = useState(null);
  const [data, setData] = useState([]);
  
  // Tier 3 State
  const [daily3, setDaily3] = useState('');
  const [transfer3, setTransfer3] = useState('');
  const [update3, setUpdate3] = useState(true);
  const [updateLimit3, setUpdateLimit3] = useState(true);

  // Tier 2 State
  const [daily2, setDaily2] = useState('');
  const [transfer2, setTransfer2] = useState('');
  const [update2, setUpdate2] = useState(true);
  const [updateLimit2, setUpdateLimit2] = useState(true);

  useEffect(() => {
    const getTierAccounts = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/account-tier`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let data = await response.json();
        
        // Sort tiers to ensure correct order
        const sortedData = data.sort((a, b) => a.tier.localeCompare(b.tier));
        setData(sortedData);

        // Initialize Tier 3 values
        const tier3 = sortedData.find(t => t.tier === "tier_3");
        setDaily3(tier3?.daily_limit);
        setTransfer3(tier3?.transfer_limit);

        // Initialize Tier 2 values
        const tier2 = sortedData.find(t => t.tier === "tier_2");
        setDaily2(tier2?.daily_limit);
        setTransfer2(tier2?.transfer_limit);

        setLoading(false);
      } catch (e) {
        // Handle error
      }
    };
    getTierAccounts();
  }, []);

  // Tier 3 Handlers
  const handleTier3Update = () => setUpdate3(prev => !prev);
  const handleTier3LimitUpdate = () => setUpdateLimit3(prev => !prev);

  // Tier 2 Handlers
  const handleTier2Update = () => setUpdate2(prev => !prev);
  const handleTier2LimitUpdate = () => setUpdateLimit2(prev => !prev);

  const saveChanges = async (tierId, dailyLimit, transferLimit) => {
    try {
      setLoading(true);
      const cleanedDaily = dailyLimit.replace(/,/g, "");
      const cleanedTransfer = transferLimit.replace(/,/g, "");

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/account-tier/${tierId}/`,
        {
          method: "PUT",
          body: JSON.stringify({
            daily_limit: cleanedDaily,
            transfer_limit: cleanedTransfer
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      const result = await response.json();
      toast.success(result.detail);
      
      // Refresh data
      const getTierAccounts = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/account-tier`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        const sortedData = data.sort((a, b) => a.tier.localeCompare(b.tier));
        setData(sortedData);
      };
      await getTierAccounts();

    } catch (e) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const formatNumber = (inputValue) => {
    const numericValue = Number(inputValue?.replace(/,/g, "") ?? "");
    if (!isNaN(numericValue)) {
      return numericValue.toLocaleString();
    }
    return inputValue;
  };

  // Find tiers in sorted data
  const tier1 = data.find(t => t.tier === "tier_1");
  const tier2 = data.find(t => t.tier === "tier_2");
  const tier3 = data.find(t => t.tier === "tier_3");

  return (
    <Wrapper>
      <PageContainer>
        <div className="table-container">
          <div className="table-head">
            <h3>Account Tier Settings</h3>
          </div>
        </div>

        {loading ? (
          <Skeleton height={"400px"} />
        ) : (
          <>
            {/* Tier 1 */}
            <TierContainer>
              <div className="heading">
                <h3>Tier 1</h3>
              </div>
              <div className="body">
                <div className="inputSect">
                  <label>Limit Per Transfer</label>
                  <input
                    type="text"
                    disabled
                    value={`₦${formatNumber(tier1?.transfer_limit)}`}
                    className="formInput"
                  />
                </div>
                <div className="inputSect">
                  <label>Daily Transaction Limit</label>
                  <input
                    type="text"
                    disabled
                    value={`₦${formatNumber(tier1?.daily_limit)}`}
                    className="formInput"
                  />
                </div>
              </div>
            </TierContainer>

            {/* Tier 2 */}
            <TierContainer>
              <div className="heading">
                <h3>Tier 2</h3>
              </div>
              <div className="body">
                <div className="inputSect">
                  <label>Limit Per Transfer</label>
                  <div className="container">
                    <span className="naira">₦</span>
                    <input
                      type="text"
                      disabled={update2}
                      onChange={(e) => setTransfer2(e.target.value)}
                      value={formatNumber(transfer2)}
                      className="formInput"
                    />
                    <Edit className="editing" onClick={handleTier2Update} />
                  </div>
                </div>
                <div className="inputSect">
                  <label>Daily Transaction Limit</label>
                  <div className="container">
                    <span className="naira">₦</span>
                    <input
                      type="text"
                      disabled={updateLimit2}
                      onChange={(e) => setDaily2(e.target.value)}
                      value={formatNumber(daily2)}
                      className="formInput"
                    />
                    <Edit className="editing" onClick={handleTier2LimitUpdate} />
                  </div>
                </div>
              </div>
              {(!update2 || !updateLimit2) && (
                <div className="btnContainer">
                  <button onClick={() => saveChanges(tier2?.id, daily2, transfer2)}>
                    Save Changes
                  </button>
                </div>
              )}
            </TierContainer>

            {/* Tier 3 */}
            <TierContainer>
              <div className="heading">
                <h3>Tier 3</h3>
              </div>
              <div className="body">
                <div className="inputSect">
                  <label>Limit Per Transfer</label>
                  <div className="container">
                    <span className="naira">₦</span>
                    <input
                      type="text"
                      disabled={update3}
                      onChange={(e) => setTransfer3(e.target.value)}
                      value={formatNumber(transfer3)}
                      className="formInput"
                    />
                    <Edit className="editing" onClick={handleTier3Update} />
                  </div>
                </div>
                <div className="inputSect">
                  <label>Daily Transaction Limit</label>
                  <div className="container">
                    <span className="naira">₦</span>
                    <input
                      type="text"
                      disabled={updateLimit3}
                      onChange={(e) => setDaily3(e.target.value)}
                      value={formatNumber(daily3)}
                      className="formInput"
                    />
                    <Edit className="editing" onClick={handleTier3LimitUpdate} />
                  </div>
                </div>
              </div>
              {(!update3 || !updateLimit3) && (
                <div className="btnContainer">
                  <button onClick={() => saveChanges(tier3?.id, daily3, transfer3)}>
                    Save Changes
                  </button>
                </div>
              )}
            </TierContainer>
          </>
        )}
      </PageContainer>
    </Wrapper>
  );
};

export default Home;
const TierContainer = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 1rem;
  margin: 3rem 2rem;
  

  .heading {
    padding: 2rem;
    border-bottom: 1px solid #e0e0e0;
    h3 {
      font-size: 1rem;
      font-weight: 500;
      color: #333333;
    }
  }
  .body {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15%;
    .inputSect {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      flex: 1;

      label {
        font-size: 0.9rem;
        font-weight: 700;
        color: #333333;
      }
      .container{
        display: flex;
        flex-direction: row;
        gap: 5px;
        position: relative;
        .editing{
          position: absolute;
          margin-left: 90%;
        }
        .naira{
          position: absolute;
          margin-top: 9px;
          margin-left: 6px;
          font-size: 14px;
        }
        .formInput {
          height: 34px;
          border-radius: 6px;
          border: 1px solid #d9d9d9;
          padding: 0 0.9rem;
          outline: none;
          width: 100%;
        }
      }

      .formInput {
          height: 34px;
          border-radius: 6px;
          border: 1px solid #d9d9d9;
          padding: 0 0.6rem;
          outline: none;
          width: 100%;
        }
    }
  }
  .btnContainer {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    button {
      border-radius: 6px;
      height: 34px;
      padding: 0 10px;
      border: none;
      outline: none;
      background-color: #d22f31;
      color: #fff;
      cursor: pointer;
    }
  }
`;